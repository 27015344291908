import { DownloadOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

// displays a page header

export default function Home() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "Build/VolleyBomb.loader.js",
    dataUrl: "Build/VolleyBomb.data",
    frameworkUrl: "Build/VolleyBomb.framework.js",
    codeUrl: "Build/VolleyBomb.wasm",
  });

  return (
    <div style={{ width: "100%" }}>
      <div className="home-header-div">
        <div className="home-header-div-inside">
          <img alt="" width={300} src="vb_icon.png" />
          <Title style={{ marginTop: 40, fontSize: 60, color: "white" }}>Play. Win. Collect.</Title>
          {/*<Title level={3} className="home-header-description" style={{ marginTop: 0 }}>
            Play in fast-paced matches, experience thrilling victories and collect cosmetic NFTs to show your opponents
            you mean business!
          </Title>
          */}
          <Title level={3} className="home-header-description" style={{ marginTop: 0, color: "white" }}>
            COMING SOON
          </Title>
          {/*
          <Button style={{ marginTop: 30 }} type="primary" shape="round" icon={<DownloadOutlined />} size="large">
            Download
          </Button>
          */}
        </div>
      </div>
      <div className="video-section">
        <iframe
          width="800"
          height="415"
          src="https://www.youtube.com/embed/d1HFXDExuaU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}
