import React from "react";
import { Typography } from "antd";

const { Title, Text } = Typography;

// displays a page header

export default function Header({ link, title, subTitle, ...props }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "1.2rem" }}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "start" }}>
        <a href={link} rel="noopener noreferrer">
          <Title level={4} style={{ margin: "0 0.5rem 0 0.5rem" }}>
            <img style={{ height: "50px" }} alt="" src="vb_logo.png" />
          </Title>
        </a>
        {/*
        <Text type="secondary" style={{ textAlign: "left" }}>
          {subTitle}
        </Text>
        */}
      </div>
      {props.children}
    </div>
  );
}

Header.defaultProps = {
  link: "/",
  title: "VolleyBomb",
  subTitle: "Forkable Ethereum dev stack focused on fast product iteration",
};
