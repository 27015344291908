import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

// displays a page header

export default function Game() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "Build/VolleyBomb.loader.js",
    dataUrl: "Build/VolleyBomb.data",
    frameworkUrl: "Build/VolleyBomb.framework.js",
    codeUrl: "Build/VolleyBomb.wasm",
  });

  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <Unity
        unityProvider={unityProvider}
        style={{ textAlign: "center", width: 1280, height: 720, objectFit: "contain !important" }}
      />
    </div>
  );
}
